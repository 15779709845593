















































































import Vue from 'vue';
import {VBtn, VDatePicker, VIcon, VMenu, VTextField} from 'vuetify/lib';
import {Component, Prop, Watch} from 'vue-property-decorator';
import * as moment from 'moment';
import {Account, InvoiceData} from '@/types';

@Component({
  components: {
    VTextField,
    VBtn,
    VMenu,
    VDatePicker,
    VIcon,
  },
})
export default class AccountsInvoice extends Vue {
  public static _updateDescription(expires: string, employeesCount: number, specialTerms: string) {
    let size = '16-30';
    if (employeesCount < 4) {
      size = '1-3';
    } else if (employeesCount < 9) {
      size = '4-8';
    } else if (employeesCount < 16) {
      size = '9-15';
    }
    let m = 'TrinnVis, årsabonnement, ';
    m += '' + size + ' personer, ';
    m += moment.utc(expires).add(1, 'day').format('DD.MM.YYYY');
    m += ' - ';
    m += moment.utc(expires).add(1, 'year').format('DD.MM.YYYY');
    if ('NFF' === specialTerms) {
      m += ' medlem NFF';
    }
    if ('DNLF' === specialTerms) {
      m += ' medlem Legeforeningen';
    }
    if ('NKF' === specialTerms) {
      m += ' medlem NKF';
    }
    if ('NTF' === specialTerms) {
      m += ' medlem NTF';
    }
    if ('NMF' === specialTerms) {
      m += ' medlem NMF';
    }
    if ('NPF' === specialTerms) {
      m += ' medlem NPF';
    }
    if ('NOF' === specialTerms) {
      m += ' medlem NOF';
    }
    if ('NNF' === specialTerms) {
      m += ' medlem NNF';
    }

    return m;
  }

  public static _nextExpires(expires: string) {
    return moment.utc(expires).add(1, 'year').format('YYYY-MM-DD');
  }

  @Prop({default: null})
  public account: Account;

  @Prop({default: null})
  public invoiceData!: InvoiceData;

  public line1 = '';
  public line2 = '';
  public line3 = '';
  public line4 = '';
  public amount1 = '';
  public amount2 = '';
  public amount3 = '';
  public amount4 = '';
  public nextExpires = '';
  public menu = false;

  public hideMenu() {
    this.menu = false;
  }

  public clear1() {
    this.line1 = '';
    this.amount1 = '';
    this.change();
  }

  public clear2() {
    this.line2 = '';
    this.amount2 = '';
    this.change();
  }

  public clear3() {
    this.line3 = '';
    this.amount3 = '';
    this.change();
  }

  public clear4() {
    this.line4 = '';
    this.amount4 = '';
    this.change();
  }

  public addFee() {
    this.line4 = 'Etablering';
    this.amount4 = '1600';
    this.change();
  }

  public change() {
    const args = {
      line1: this.line1,
      amount1: this.amount1,
      line2: this.line2,
      amount2: this.amount2,
      line3: this.line3,
      amount3: this.amount3,
      line4: this.line4,
      amount4: this.amount4,
      nextExpires: this.nextExpires,
    };
    this.$emit('change', args);
  }

  get nextExpiresFormatted() {
    return moment.utc(this.nextExpires).format('D.M.YYYY');
  }

  @Watch('invoiceData', { immediate: true, deep: true })
  private onInvoiceDataChanges(val: InvoiceData) {
    this.line1 = val.line1;
    this.line2 = val.line2;
    this.line3 = val.line3;
    this.line4 = val.line4;
    this.amount1 = val.amount1;
    this.amount2 = val.amount2;
    this.amount3 = val.amount3;
    this.amount4 = val.amount4;
    this.nextExpires = val.nextExpires;
  }
/*
  @Watch('account', { immediate: true, deep: true })
  private onAccountChanges(val: Account) {
    this.line1 = AccountsInvoice._updateDescription(
        val.expires,
        val.employees.filter(e => e.email === null || !e.email.endsWith("@helsekontor.no")).length,
        val.specialTerms);
    this.line2 = '';
    this.line3 = '';
    this.line4 = '';
    this.amount1 = '' + val.amount;
    this.amount2 = '';
    this.amount3 = '';
    this.amount4 = '';
    this.nextExpires = AccountsInvoice._nextExpires(val.expires);
    // this.change();
  }
*/
  /*
  @Watch('account.expires')
  private onExpiresChanged(val: string, oldVal: string) {
    this.line1 = AccountsInvoice._updateDescription(
        this.account.expires,
        this.account.employees.filter(e => e.email === null || !e.email.endsWith("@helsekontor.no")).length,
        this.account.specialTerms);

    this.nextExpires = AccountsInvoice._nextExpires(this.account.expires);
    this.change();
  }

   */

}
