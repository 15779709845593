
















































































































































































































import AccountsResultComments from './AccountsResultComments.vue';
import AccountsResultEmployee from './AccountsResultEmployee.vue';
import AccountsInvoice from './AccountsInvoice.vue';
import AccountsResultStatus from './AccountsResultStatus.vue';

import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VChip,
  VCol,
  VCombobox,
  VContainer,
  VDialog,
  VIcon,
  VList,
  VListItem,
  VListItemAction,
  VListItemSubtitle,
  VListItemTitle,
  VMenu,
  VRow,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
} from 'vuetify/lib';
import * as moment from 'moment';
import AccountsResultIndicators from '@/components/AccountsResultIndicators.vue';
import {Account, InvoiceData} from '@/types';

@Component({
  components: {
    AccountsResultEmployee,
    AccountsResultComments,
    AccountsResultStatus,
    AccountsInvoice,
    VCard,
    VCheckbox,
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
    VBtn,
    VIcon,
    AccountsResultIndicators,
    VCombobox,
    VChip,
    VMenu,
    VDialog,
    VList,
    VListItem,
    VListItemAction,
    VListItemTitle,
    VListItemSubtitle,
    VCardActions,
    VCardTitle,
    VCardText,
    VContainer,
    VRow,
    VCol,
    VTextField,
  },
})
export default class AccountsResultItem extends Vue {
  private static sortObj(list: any[], key: string): any[] {
    function compare(a: any, b: any): number {
      a = a[key];
      b = b[key];
      if (a == null) {
        return -1;
      }
      if (b == null) {
        return -1;
      }
      const type =
          typeof a === 'string' || typeof b === 'string' ? 'string' : 'number';
      let result;
      if (type === 'string') {
        result = a.localeCompare(b);
      } else {
        result = a - b;
      }
      return result;
    }

    return list.concat().sort(compare);
  }

  @Prop()
  public account: Account;

  @Prop()
  public invoiceData: InvoiceData;

  @Prop({default: false})
  public checked: boolean;

  @Prop({default: false})
  public dialog: boolean;

  @Prop({default: false})
  public dialogLoginUser: boolean;
  @Prop({default: false})
  public meetings: boolean;

  @Prop({default: false})
  public menu: boolean;

  @Prop({default: 'x'})
  public tab: string = 'x';
  @Prop({default: ''})
  public reason: string = '';
  @Prop({default: ''})
  public code: string = '';
  @Prop({default: ''})
  public reasonLoginUser: string = '';
  @Prop({default: ''})
  public codeLoginUser: string = '';

  @Prop({default: null})
  public indicators: Array<{
    description: string;
    color: string;
    filteredValues: number[];
  }>;

  public currentTab = this.tab;

  get formattedCreatedDate(): string {
    return moment.utc(this.account.createdDate).format('D.M.YYYY');
  }

  public search: string = '';

  get select(): string[] {
    return (this.account && this.account.tags) || [];
  }

  set select(newValue: string[]) {
    this.$emit('tags', {tags: newValue, id: this.account.id});
  }

  get chipColor(): string {
    if (this.account.features.includes("MINI")) {
      return 'green'
    } else if (this.account.features.includes("BASIC")) {
      return 'orange'
    } else if (this.account.features.includes("EXTRA")) {
      return 'red'
    } else {
      return 'yellow';
    }
  }

  @Prop({default: []})
  public allTags: string[];

  public colors: string[] = [
    'green',
    'purple',
    'indigo',
    'cyan',
    'teal',
    'orange',
  ];

  public nonce = 1;

  public loginUserEmail: string = '';

  get employeesSorted() {
    return AccountsResultItem.sortObj(this.account.employees, 'firstName');
  }

  public itemFeatureChanged(e: any) {
    let target = e.target as HTMLInputElement;
    if (e.target.selected) {
      this.addFeature(target.value)
    } else {
      this.removeFeature(target.value)
    }
  }

  public loginUser(e: any) {
    this.loginUserEmail = e;
    this.dialogLoginUser = true;
  }

  public loginUserOpen(e: any) {
    this.dialogLoginUser = false;
    this.$emit('loginUser',
        {
          userEmail: this.loginUserEmail,
          id: this.account.id,
          reason: this.reasonLoginUser,
          code: this.codeLoginUser,
        });
    this.loginUserEmail = '';
    this.reasonLoginUser = '';
    this.codeLoginUser = '';
  }

  public featureSelected(f: string): boolean {
    return this.account.features.includes(f);
  }

  public statusChanged(e: any) {
    this.$emit('statusChanged', {message: e, id: this.account.id});
  }

  public deleteAccount(e: any) {
    this.$emit('deleteAccount', {
      id: this.account.id,
      username: this.account.contactEmail,
    });
  }

  public updateAccountPlan(f: string, i: string) {
    this.$emit('updateAccountPlan', {
      id: this.account.id,
      featurePlan: f,
      invoicePlan: i,
    });
  }
  public addFeature(f: string) {
    this.$emit('updateAccountPlan', {
      id: this.account.id,
      addFeature: f,
    });
  }
  public removeFeature(f: string) {
    this.$emit('updateAccountPlan', {
      id: this.account.id,
      removeFeature: f,
    });
  }

  public updateAccountPlanMini() {
    this.updateAccountPlan('A', 'A');
  }

  public updateAccountPlanBasis() {
    this.updateAccountPlan('B', 'B');
  }

  public updateAccountPlanBasisMedMini() {
    this.updateAccountPlan('B', 'A');
  }

  public updateAccountPlanEkstra() {
    this.updateAccountPlan('C', 'C');
  }

  public loginOwner() {
    this.dialog = false;
    this.$emit('loginUser', {
      userEmail: this.account.contactEmail,
      id: this.account.id,
      reason: this.reason,
      code: this.code,
    });
    this.reason = '';
    this.code = '';
  }

  public invoiceChanged(e: any) {
    this.$emit('invoiceChanged', {invoiceData: e, id: this.account.id});
  }

  get expiresFormatted(): string {
    return moment.utc(this.account.expires).format('D.M.YYYY');
  }
}
