import {css, html, LitElement, nothing} from 'lit'
import {customElement, property} from 'lit/decorators.js';
import './use-aspects';

@customElement('app-root')
export class AppRoot extends LitElement {

  static styles = css`
    :host {
      display: block;
    }
    header {
      display: flex;
      box-sizing: border-box;
      max-height: 40px;
      padding: 0 20px;
      overflow: hidden;
      cursor: pointer;
      transition: max-height 0.3s;
      background-color: hsl(196, 83%, 49%);
    }
    header:hover {
      max-height: 100px;
      cursor: default;
    }
    header span {
      margin: 20px 0;
      padding: 20px;
      opacity: .5;
      cursor: pointer;
    }
    header span[selected] {
      opacity: 1;
      cursor: default;
    }
    header span:hover {
      opacity: 1;
    }
  `
  @property({type: String})
  view = 'statsOverview';

  @property({type: Array, attribute: 'usage-index'})
  usageIndex: string[] = [];

  setView(view: string) {
    this.view = view;
  }

  selected(view: string) {
    return view === this.view;
  }

  render() {
    return html`
      <header>
        <span 
          ?selected=${this.selected('statsOverview')}
          @click=${() => this.setView('statsOverview')}
        >Admin</span>
        <span
          ?selected=${this.selected('useAspects')}
          @click=${() => this.setView('useAspects')}
        >Bruksaspekter</span>
      </header>
      ${this.view === 'statsOverview' ? html`
        <slot></slot>
      ` : nothing}
      ${this.view === 'useAspects' ? html`
        <use-aspects .usageIndex=${this.usageIndex} ></use-aspects>
      ` : nothing}
    `;
  }

}

declare global {
  interface HTMLElementTagNameMap {
    'app-root': AppRoot;
  }
}
