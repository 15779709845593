import {css, html, LitElement} from 'lit'
import {customElement, property} from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import type { BarGraphData, Indicators } from './types';

@customElement('bar-graph')
export class BarGraph extends LitElement {

  static styles = css`
    :host {
      display: block;
      padding: 18px 0 18px 0;
    }
    
    :host * {
      box-sizing: border-box;
    }
    
    .bar {
      display: flex;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
    }
    
    .bar > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 200;
    }

    .legends {
      display: flex;
      flex-wrap: wrap;
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s;
      color: hsla(0, 0%, 100%, .7);

    }

    :host([selected]) .legends {
      max-height: 300px;
    }

    .legends > div {
      width: 30%;
    }

    .legends > div > div:first-child {
      margin-top: 16px;
      margin-bottom: 10px;
      font-size: 17px;
      font-weight: 600;
    }

    .legends ul {
      list-style-type: none;
      margin: 0 20px 0 0;
      padding: 0;
      font-size: 14px;
      font-weight: 200;
      line-height: 150%;
      hyphens: auto;
    }

    .legends li:not(:last-child) {
      padding-bottom: 10px;
    }
  `

  @property({type: Object})
  item!: { label: string; values: number[]; indicators: Indicators}
  @property({type: String})
  color = '201, 48%, 46%'

  get totalValue () {
    let count = 0
    this.item.values.forEach((item) => {
      count += item
    })
    return count
  }

  percent (count: number) {
    return count / this.totalValue * 100
  }

  get itemsWithStyle (): BarGraphData[] {
    const result: BarGraphData[] = []
    let opacity = 1
    this.item.values.forEach((item) => {
      result.push({
        value: item,
        color: 'hsla(' + this.color + ', ' + opacity + ')'
      })
      opacity = opacity - 0.25
    })
    return result
  }

  legendStyle(opacity: number) {
    return 'color: hsla(' + this.color + ', ' + opacity + ')'
  }

  render () {
    return html`
      <div class="bar">
        ${this.itemsWithStyle.map((item) => html`
        <div style="flex-basis: ${this.percent(item.value)}%; background: ${item.color}">
        </div>
      `)}
      </div>
      <div class="legends">
        <div>
          <div style="${this.legendStyle(1)}">Mye bruk: ${this.percent(this.item.values[0]).toFixed(0)} %</div>
          <div>${unsafeHTML(this.item.indicators.high)}</div>
        </div>
        <div>
          <div style="${this.legendStyle(0.75)}">Middels bruk: ${this.percent(this.item.values[1]).toFixed(0)} %</div>
          <div>${unsafeHTML(this.item.indicators.medium)}</div>
        </div>
        <div>
          <div style="${this.legendStyle(0.5)}">Litt bruk: ${this.percent(this.item.values[2]).toFixed(0)} %</div>
          <div>${unsafeHTML(this.item.indicators.low)}</div>
        </div>
      </div>
    `
  }

}

declare global {
  interface HTMLElementTagNameMap {
    'bar-graph': BarGraph;
  }
}
