































































import {Component, Vue} from 'vue-property-decorator';
import AccountsForm from '@/components/AccountsForm.vue';
import AmplifyStore from '../store';
import AdminClient, {LoginOptions, SearchOptions} from '@/admin-client';
import PQueue from 'p-queue';
import {AdminPropertiesData, RuleStructure} from '@/types';
import {Map, Set} from 'immutable';
import '../components/app-root'
@Component({
  components: {
    AccountsForm,
  },
})
export default class Home extends Vue {

  get user() {
    return AmplifyStore.state.user;
  }

  get showRules() {
    return this.user !== null && (this.user.username === 'kristian' || this.user.username === 'siren');
  }

  public showIndicators = true;
  public showMenu = false;

  private rulesData: RuleStructure[] = [];

  private get adminPropertiesData(): AdminPropertiesData {
    return this.$store.state.adminPropertiesData;
  }

  private snackbar = false;
  private snackbarText = '';
  private allTags: string[] = [];
  private usageIndex: string[] = [];


  private rulesLoaded = false;
  private adminLoaded = false;

  private lastSearchOptions: SearchOptions | null = null;

  public created() {
    this.rules();
    this.adminProperties();
    this.loadAllTags();
    this.usage();
  }

  private menuClick() {
    this.showMenu = !this.showMenu;
  }

  private menuClosed() {
    this.showMenu = false;
  }

  private closeMenu(e: Event) {
    const t = e.target as HTMLElement;
    if (t.id === 'reindex') {
      this.snackbarText = `Admin reindekseres. Dette kan ta intill 5 minutter.`;
      this.snackbar = true;

      AdminClient.reindex().then((r: any) => {
        this.snackbarText = `Reindeksering ferdig`;
        this.snackbar = true;
        console.log(r)

      }).catch((err: any) => {
        console.log(err);
        this.snackbarText = `Reindeksering feilet: ` + err.toString();
        this.snackbar = true;

      });
    }
  }

  private search(searchOptions: SearchOptions) {
    if (this.rulesLoaded && this.adminLoaded) {

      this.lastSearchOptions = searchOptions;
      AdminClient.search(searchOptions, this.adminPropertiesData.included, this.adminPropertiesData.excluded)
          .then((data: any) => {
            console.log(data.data)
            this.$store.commit('setResponse', data.data);
          });
    }
  }

  private initialSearch() {

    this.search({
      closed: 'false',
      createdYear: '',
      createdYearMonth: '',
      expiresMonth: '',
      from: 0,
      q: '',
      size: 10,
      terms: '',
      group: '',
      features: '',
      tags: '',
    });

  }

  private rules() {
    AdminClient.rules().then((data) => {
      this.rulesData = data.data;
      this.rulesLoaded = true;
      this.initialSearch();
    });
  }

  private loadAllTags() {
    AdminClient.allTags().then((data) => {
      this.allTags = data.data;
    });
  }

  private usage() {
    AdminClient.usageIndex().then((data) => {
      this.usageIndex = data.data;
    })
  }

  private adminProperties() {
    return AdminClient.adminProperties().then((response) => {

      const a: AdminPropertiesData = {
        favorites: Set.of(...(response.data.favorites || [])),
        included: Set.of(...(response.data.included || [])),
        excluded: Set.of(...(response.data.excluded || [])),
        colors: Map(response.data.colors || {}),
        showOnlyFavorites: response.data.showOnlyFavorites || false,
      };
      this.$store.commit('updateAdminPropertiesData', a);
      this.adminLoaded = true;
      this.initialSearch();
    });
  }

  private loginUser(e: LoginOptions) {
    AdminClient.login(e).then((data: any) => {
      const l = data.data.location;
      window.open(l, 'trinnvis_admin_opened');
    });
  }

  private accountStatusChanged(e: any): any {
    this.snackbarText = `Konto ${e.id} status oppdateres`;
    this.snackbar = true;
    AdminClient.updateStatus(e.id, e.message).then(() => {
      this.snackbarText = `Konto ${e.id} status oppdatert`;
      this.snackbar = true;
      // this.search(e.searchOptions);

      const resp = this.$store.state.response;
      if (resp && resp.hits) {
        const r = JSON.parse(JSON.stringify(resp));
        r.hits.forEach((item: { id: string, closed: boolean, expires: string }) => {
          if (item.id === e.id) {
            item.closed = e.message.closed;
            item.expires = e.message.expires;
          }
        });
        this.$store.commit('setResponse', r);
      }
    });
  }

  private updateAccountPlan(e: any): any {
    this.snackbarText = `Konto ${e.id} fakturaplan oppdatere ${e.featurePlan} ${e.invoicePlan}`;
    this.snackbar = true;
    if (e.featurePlan && e.invoicePlan) {
      AdminClient.updateAccountPlan(e.id, {featurePlan: e.featurePlan, invoicePlan: e.invoicePlan}).then(() => {
        this.snackbarText = `Konto ${e.id} fakturaplan oppdatert, venter på oppdatert data`;
        this.snackbar = true;
        setTimeout(() => {
          // 4 second delay here before running next line
          this.search(e.searchOptions);
        }, 4000);


        /*
              const resp = this.$store.state.response;
              if (resp && resp.hits) {
                const r = JSON.parse(JSON.stringify(resp));
                r.hits.forEach((item: { id: string, closed: boolean, expires: string }) => {
                  if (item.id === e.id) {
                    item.closed = e.message.closed;
                    item.expires = e.message.expires;
                  }
                });
                this.$store.commit('setResponse', r);

              }
                */
      });

    } else if (e.addFeature) {
      AdminClient.updateAccountPlanAddFeature(e.id, {addFeature: e.addFeature}).then(() => {
        this.snackbarText = `Konto ${e.id} fakturaplan oppdatert, venter på oppdatert data`;
        this.snackbar = true;
        setTimeout(() => {
          // 4 second delay here before running next line
          this.search(e.searchOptions);
        }, 4000);


        /*
              const resp = this.$store.state.response;
              if (resp && resp.hits) {
                const r = JSON.parse(JSON.stringify(resp));
                r.hits.forEach((item: { id: string, closed: boolean, expires: string }) => {
                  if (item.id === e.id) {
                    item.closed = e.message.closed;
                    item.expires = e.message.expires;
                  }
                });
                this.$store.commit('setResponse', r);

              }
                */
      });
    }else if (e.removeFeature) {
      AdminClient.updateAccountPlanRemoveFeature(e.id, {removeFeature: e.removeFeature}).then(() => {
        this.snackbarText = `Konto ${e.id} fakturaplan oppdatert, venter på oppdatert data`;
        this.snackbar = true;
        setTimeout(() => {
          // 4 second delay here before running next line
          this.search(e.searchOptions);
        }, 4000);


        /*
              const resp = this.$store.state.response;
              if (resp && resp.hits) {
                const r = JSON.parse(JSON.stringify(resp));
                r.hits.forEach((item: { id: string, closed: boolean, expires: string }) => {
                  if (item.id === e.id) {
                    item.closed = e.message.closed;
                    item.expires = e.message.expires;
                  }
                });
                this.$store.commit('setResponse', r);

              }
                */
      });
    }
  }

  private deleteAccount(e: any): any {
    this.snackbarText = `Konto ${e.id} SLETTES`;
    this.snackbar = true;
    AdminClient.deleteAccount(e.id, e.username).then(() => {
      this.snackbarText = `Konto ${e.id} slettet`;
      this.snackbar = true;
      this.search(e.searchOptions);
    });
  }

  private tags(r: any) {
    AdminClient.tags(r.id, {tags: r.tags}).then(() => {
      this.snackbarText = `Konto ${r.id} oppdatert tags`;
      this.snackbar = true;
      this.loadAllTags();
    });
  }

  private invoiceSelected(r: any) {
    this.snackbarText = `Fakturerer ${r.items.length}`;
    this.snackbar = true;

    const queue = new PQueue({concurrency: 1, interval: 5000, intervalCap: 1});

    r.items.forEach((i: any) => {
      queue.add(() => {
        this.snackbarText = `Fakturerer ${i.id}: ${i.account.organizationName}`;
        this.snackbar = true;
        return AdminClient.invoiceAccount(i.id, i.invoiceData);
      });
    });

    queue.add(() => {
      this.snackbarText = `Oppdaterer søk`;
      this.snackbar = true;
      this.search(r.searchOptions);
    });
  }

  private deleteFavorite(d: { key: string }) {
    const s = this.adminPropertiesData.favorites.delete(d.key);
    const v = Array.from(s.values());
    AdminClient.updateProperty('favorites', v).then(() => {
      const newVar = {...this.adminPropertiesData, favorites: s};
      this.$store.commit('updateAdminPropertiesData', newVar);
    });
  }

  private async clearSelections() {

    const a = {...this.adminPropertiesData, colors: Map(), included: Set(), excluded: Set()};

    const v3: { [k: string]: string } = {};
    await AdminClient.updateProperty('colors', v3);
    const v: string[] = [];
    await AdminClient.updateProperty('included', v);

    const v2: string[] = [];
    await AdminClient.updateProperty('excluded', v2);

    this.$store.commit('updateAdminPropertiesData', a);
    if (this.lastSearchOptions != null) {
      this.search(this.lastSearchOptions);
    }
  }


  private async updateFilter(d: { key: string, include: boolean, exclude: boolean }) {
    const s = d.include ?
        this.adminPropertiesData.included.add(d.key) :
        this.adminPropertiesData.included.delete(d.key);

    const v = Array.from(s.values());
    await AdminClient.updateProperty('included', v);
    const s2 = d.exclude ?
        this.adminPropertiesData.excluded.add(d.key) :
        this.adminPropertiesData.excluded.delete(d.key);
    const v2 = Array.from(s2.values());
    await AdminClient.updateProperty('excluded', v2);

    const newVar = {...this.adminPropertiesData, included: s, excluded: s2};
    this.$store.commit('updateAdminPropertiesData', newVar);

    if (this.lastSearchOptions != null) {
      this.search(this.lastSearchOptions);
    }
  }

  private async addFavorite(d: { key: string }) {
    const s = this.adminPropertiesData.favorites.add(d.key);
    const v = Array.from(s.values());
    await AdminClient.updateProperty('favorites', v);
    const newVar = {...this.adminPropertiesData, favorites: s};
    this.$store.commit('updateAdminPropertiesData', newVar);

  }

  private async selectColor(d: { key: string; color: string }) {
    const c = d.color === '#EEEEEE' ?
        this.adminPropertiesData.colors.delete(d.key) :
        this.adminPropertiesData.colors.set(d.key, d.color);

    const v: { [k: string]: string } = {};
    for (const [key, value] of c) {
      v[key] = value;
    }
    await AdminClient.updateProperty('colors', v);
    const newVar = {...this.adminPropertiesData, colors: c};
    this.$store.commit('updateAdminPropertiesData', newVar);

  }


}
