import {css, html, LitElement} from 'lit'
import {customElement, property} from 'lit/decorators.js';
import './bar-graph';
import {GraphItem, UseAspectLevels, ValueText} from './types';
import AdminClient from "@/admin-client";

@customElement('use-aspects')
export class UseAspects extends LitElement {

    static styles = css`
        :host {
            display: block;
            padding: 0 40px 0 40px;
            background: #2F2E22;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 12px;
            font-weight: 500;
            font-size: 24px;
            color: hsla(0, 0%, 100%, .7);
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        tr {
            cursor: pointer;
        }

        tr:not([selected]):hover {
            background: hsla(0, 0%, 0%, .1);
        }

        td {
            vertical-align: top;
            border-top: 1px solid hsla(0, 0%, 100%, .1);
        }

        tr:last-child td {
            border-bottom: 1px solid hsla(0, 0%, 100%, .1);
        }

        td h2 {
            margin: 0;
            padding: 18px 0 18px 0;
            font-size: 20px;
            font-weight: normal;
            line-height: 100%;
        }

        td p {
            margin: 8px 0 0 0;
            font-size: 15px;
            line-height: 120%;
            font-weight: 200;
        }

        td:first-child {
            padding-right: 20px;
            text-align: right;
        }

        td:last-child {
            width: 100%;
        }
    `
    @property({type: Number})
    selectedAspect = -1;
    @property({type: Object})
    useAspects: ValueText[] = [
        {
            value: 'archiveUsageLevels',
            text: 'Arkiv',
            indicators: {
                high: '<ul><li>Minst 2 x antall personer redigerte stiftelsesdokumenter eller avtaler eller vedlegg til personer og samarbeidspartnere</li></ul>',
                medium: '<ul><li>Minst 1 x antall personer redigerte stiftelsesdokumenter eller avtaler eller vedlegg til personer og samarbeidspartnere</li></ul>',
                low: '<ul><li>Minst 1 redigert stiftelsesdokument eller avtale eller vedlegg til personer og samarbeidspartnere</li></ul>',
            },
        },
        {
            value: 'referenceUsageLevels',
            text: 'Oppslagsverk',
            indicators: {
                high: '<ul><li>Minst 30 viste dokumenter (som har versjoner) siste 12 måneder, uten at de er endret samme dag</li></ul>',
                medium: '<ul><li>Minst 10 viste dokumenter (som har versjoner) siste 12 måneder, uten at de er endret samme dag</li></ul>',
                low: '<ul><li>Minst 2 viste dokumenter (som har versjoner) siste 12 måneder, uten at de er endret samme dag</li></ul>',
            }
        },
        {
            value: 'organizingUsageLevels',
            text: 'Organisering',
            indicators: {
                high: '<ul><li>Minst 80% av personalet er tildelt minst 1 ansvarsområde og</li><li>Minst 10 rutiner er opprettet, endret flyttet eller slettet</li></ul>',
                medium: '<ul><li>Minst 50% av personalet er tildelt minst 1 ansvarsområde</li><li>Minst 4 rutiner er opprettet, endret flyttet eller slettet</li></ul>',
                low: '<ul><li>Minst 2 personer er tildelt minst 1 ansvarsområde</li></ul>',
            }
        },
        {
            value: 'eventsUsageLevels',
            text: 'Oppgaver',
            indicators: {
                high: '<ul><li>Minst 10 x antall personer med tilgang manuelt redigerte oppgaver siste 12 måneder</li></ul>',
                medium: '<ul><li>Minst 4 x antall personer med tilgang manuelt redigerte oppgaver siste 12 måneder</li></ul>',
                low: '<ul><li>Minst 1 x antall personer med tilgang manuelt redigerte oppgaver siste 12 måneder</li></ul>',
            }
        },
        {
            value: 'meetingsUsageLevels',
            text: 'Møter',
            indicators: {
                high: '<ul><li>Minst 6 møter opprettet siste 12 måneder eller</li><li>minst 3 møteinnkallinger sendt siste 12 måneder eller</li><li>minst 3 referater delt siste 12 måneder</li></ul>',
                medium: '<ul><li>Minst 3 møter opprettet siste 12 måneder eller</li><li>minst 1 møteinnkalling sendt siste 12 måneder eller</li><li>minst 1 referat delt siste 12 måneder</li></ul>',
                low: '<ul><li>Minst 1 møte opprettet siste 12 måneder</li></ul>',
            }
        },
        {
            value: 'staffingUsageLevels',
            text: 'Bemanning',
            indicators: {
                high: '<ul><li>Minst 6 x antall personer arbeidsmønstre eller fraværsperioder registrert siste 12 måneder</li></ul>',
                medium: '<ul><li>Minst 3 x antall personer arbeidsmønstre eller fraværsperioder registrert siste 12 måneder</li></ul>',
                low: '<ul><li>Minst 1 x antall personer arbeidsmønstre eller fraværsperioder registrert siste 12 måneder</li></ul>',
            }
        },
        {
            value: 'computersUsageLevels',
            text: 'Datasikkerhet',
            indicators: {
                high: '<ul><li>Minst 5 datasystemer registrert eller</li><li>Minst 5 datamaskiner registrert eller</li><li>Minst 5 datarelaterte risikovurderinger gjennomført og</li><li>Minst 80% av personalet påmeldt datasikkerhetskurset</li></ul>',
                medium: '<ul><li>Minst 3 datasystemer registrert eller</li><li>Minst 3 datamaskiner registrert eller</li><li>Minst 3 datarelatert risikovurdering gjennomført eller</li><li>Minst 50% av personalet påmeldt datasikkerhetskurset</li></ul>',
                low: '<ul><li>Minst 1 datasystem registrert eller</li><li>Minst 1 datamaskin registrert eller</li><li>Minst 1 datarelatert risikovurdering gjennomført eller</li><li>Minst 1 person påmeldt datasikkerhetskurset</li></ul>',
            }
        },
        {
            value: 'issuesUsageLevels',
            text: 'Avvik',
            indicators: {
                high: '<ul><li>Minst 2 x antall personer avvik registrert siste 12 måneder og</li><li>Minst 60% av disse avvikene er endret</li></ul>',
                medium: '<ul><li>Minst 2 x antall personer avvik registrert siste 12 måneder</li></ul>',
                low: '<ul><li>Minst 1 avvik registrert</li></ul>',
            }
        },
        {
            value: 'riskAssessmentsUsageLevels',
            text: 'Risiko',
            indicators: {
                high: '<ul><li>Minst 10 risikovurderinger gjennomført</li></ul>',
                medium: '<ul><li>Minst 5 risikovurderinger gjennomført</li></ul>',
                low: '<ul><li>Minst 1 risikovurdering gjennomført</li></ul>',
            }
        },
        {
            value: 'assetsUsageLevels',
            text: 'Utstyr',
            indicators: {
                high: '<ul><li>Minst 5 x antall personer utstyrsenheter registrert eller</li><li>Minst 2 x antall personer utstyrsenheter med rutine</li></ul>',
                medium: '<ul><li>Minst 3 x antall personer utstyrsenheter registrert eller</li><li>Minst 1 x antall personer utstyrsenheter med rutine</li></ul>',
                low: '<ul><li>Minst 1 x antall personer utstyrsenheter registrert</li></ul>',
            }
        },
        {
            value: 'substancesUsageLevels',
            text: 'Stoffkartotek',
            indicators: {
                high: '<ul><li>Minst 20 datablader lastet opp og</li><li>Minst 3 risikovurderinger gjennomført</li></ul>',
                medium: '<ul><li>Minst 10 datablader lastet opp</li></ul>',
                low: '<ul><li>Minst 3 datablader lastet opp</li></ul>',
            }
        },
        {
            value: 'humanResourcesUsageLevels',
            text: 'HR/personal',
            indicators: {
                high: '<ul><li>Minst 75% av personer har mobiltelefon registrert og</li><li>Minst 50% av personer har avtale opprettet eller endret eller vedlegg lastet opp</li></ul>',
                medium: '<ul><li>Minst 75% av personer har mobiltelefon registrert</li></ul>',
                low: '<ul><li>0 personer har default navn</li></ul>',
            }
        },
        {
            value: 'maxUsageLevels',
            text: 'Minst ett',
            indicators: {
                high: '<ul><li>Minst ett bruksaspekt med mye bruk</li></ul>',
                medium: '<ul><li>Minst ett bruksaspekt med middels bruk</li></ul>',
                low: '<ul><li>Minst ett bruksaspekt med litt bruk</li></ul>',
            }
        },
        {
            value: 'minMaxUsageLevels',
            text: 'Minst tre',
            indicators: {
                high: '<ul><li>Minst tre bruksaspekter med mye bruk</li></ul>',
                medium: '<ul><li>Minst tre bruksaspekter med minst middels bruk</li></ul>',
                low: '<ul><li>Minst tre bruksaspekter med minst litt bruk</li></ul>',
            }
        },
    ]
    @property({type: Object})
    userStats: UseAspectLevels = {
        "archiveUsageLevels": [311, 286, 143, 447],
        "referenceUsageLevels": [330, 146, 180, 531],
        "organizingUsageLevels": [142, 321, 301, 423],
        "eventsUsageLevels": [896, 202, 61, 28],
        "meetingsUsageLevels": [872, 265, 32, 18],
        "staffingUsageLevels": [911, 108, 85, 83],
        "computersUsageLevels": [420, 180, 516, 71],
        "issuesUsageLevels": [554, 614, 2, 17],
        "riskAssessmentsUsageLevels": [663, 185, 116, 223],
        "assetsUsageLevels": [565, 261, 155, 206],
        "substancesUsageLevels": [1008, 63, 107, 9],
        "humanResourcesUsageLevels": [34, 546, 511, 96],
        "maxUsageLevels": [8, 147, 221, 811],
        "minMaxUsageLevels": [133, 327, 362, 365]
    }

    @property({type: Array, attribute: 'usage-index'})
    usageIndex: string[] = [];

    @property({type: String})
    currentUsageKey: string = '';

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        super.updated(changedProperties);

        // Check if the apiEndpoint property has changed
        if (changedProperties.has('usageIndex')) {
            this.currentUsageKey = this.usageIndex[this.usageIndex.length - 1];
        }

        if (changedProperties.has('currentUsageKey')) {
            if (this.currentUsageKey !== '') {

                AdminClient.usageData(this.currentUsageKey).then(data => {
                    this.userStats = data.data;
                })

            }
        }
    }


    get graphItems(): GraphItem[] {
        return this.useAspects.map((aspect) => {
            const values = [...this.userStats[aspect.value]]
            values.reverse();
            return {
                label: aspect.text,
                values,
                indicators: aspect.indicators
            }
        })
    }

    select(index: number) {
        this.selectedAspect = index;
    }

    selected(index: number) {
        return index === this.selectedAspect;
    }

    formatDate(d: string): string {
        if (d === '') return '';
        const s = d.split('-')
        return this.removeLeadingZeros(s[2]) + '.' + this.removeLeadingZeros(s[1]) + '.' + s[0];
    }

    removeLeadingZeros(numStr: string): string {
        return numStr.replace(/^0+/, '') || '0'; // Ensure that if the string is all zeros, it returns '0'
    }

    render() {
        console.log('graphItems', this.graphItems)
        return html`
            <h2>Bruksaspekter <span @click=${() => {
                const i = this.usageIndex.indexOf(this.currentUsageKey);
                if (i > 0) this.currentUsageKey = this.usageIndex[i - 1];
            }}>&larr;</span> ${this.formatDate(this.currentUsageKey)} <span @click=${() => {
                const i = this.usageIndex.indexOf(this.currentUsageKey);
                if (i < this.usageIndex.length - 1) this.currentUsageKey = this.usageIndex[i + 1];
            }}>&rarr;</span></h2>
            <table>
                ${this.graphItems.map((item, index) => html`
                    <tr
                            @click=${() => this.select(index)}
                            ?selected=${this.selected(index)}
                    >
                        <td>
                            <h2>${item.label}</h2>
                        </td>
                        <td>
                            <bar-graph
                                    ?selected=${this.selected(index)}
                                    .item=${item}
                            ></bar-graph>
                        </td>
                    </tr>
                `)}
            </table>
        `
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'use-aspects': UseAspects;
    }
}
